.simply-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1100;
  display: flex;
}

.simply-modal__header {
  display: flex;
  padding: 16px 48px 16px 16px;
  border-bottom: 1px solid #eff2f7;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
  color: #212529;
}

.simply-modal__body {
  max-height: calc(100vh - 60px);
  overflow: auto;
}

.simply-modal__close {
  position: absolute;
  right: 0;
  width: 48px;
  height: 100%;
  top: 0;
  background-image: url("./../images/close_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.swal-modal {
  & .swal-footer {
    text-align: center;
  }
}

.swal-title {
  font-size: 20px;
}

.swal-icon--success + .swal-text{
  text-align: center;
}

.confirm-simple-modal {
  & .swal-button--cancel {
    //background-color: #00719c;
    //color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    line-height: 1rem;
    text-align: center;
    //border: 1px solid transparent;
    height: 32px;
    border-radius: 2px;
    padding: 4px 32px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);

    background: #e2291c;
    border: 0.5px solid #e2291c;
    color: #fdfeff;

    &:not([disabled]):hover {
      background-color: #fff !important;
      color: #e2291c;
      border: 1px solid #e2291c;

      &:not([disabled]):active {
        background: #e2291c;
        border: 1px solid rgba(226, 41, 28, 0.25);
        border-radius: 2px;
      }
    }
  }

  & .swal-button--confirm {
    //background-color: #fff;
    //color: #00719c;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    line-height: 1rem;
    text-align: center;
    //border: 1px solid transparent;
    height: 32px;
    border-radius: 2px;
    padding: 4px 32px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
    background: #009731;
    // border: 0.5px solid #e9e9e9;
    color: #fdfeff;
    border: 1px solid transparent;


    &:not([disabled]):hover {
      background-color: #FFFFFF !important;
      border: 1px solid transparent;
      color: #009731;
      &:not([disabled]):active {
        background-color: #FFFFFF !important;
        color: #009731;
        border: 1px solid rgba(0, 151, 49, 0.25);
        border-radius: 2px;
      }
    }
  }
}
