.simple-select.small {
  line-height: 16px;
  font-size: 12px;
}

.simple-select {
  flex: 1 1 auto;
  line-height: 22px;
  font-size: 14px;

  &.border-danger {
    & .simple-select__control {
      border-color: red !important;
    }
  }

  &.simple-select__without-border {
    .simple-select__control {
      border-color: transparent;
    }

    .simple-select__value-container {
      padding: 2px;
    }

    .simple-select__indicators {
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  &.simple-select--is-disabled {
  }

  .simple-select__control {
    width: 100%;
    height: 100%;
    min-height: auto;
    /*border: none;*/
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.14);
    background: var(--grey4);
    border: 1px solid var(--grey4);
    border-radius: 6px;
    color: var(--black);
    min-width: 58px;

    &:hover {
      background: var(--white);
      border: 1px solid var(--grey4);
    }

  }

  .simple-select__control.simple-select__control--is-disabled {
    background-color: var(--grey7);
  }

  .simple-select__input {
    height: 100%;
  }

  .simple-select__control--menu-is-open {
    border: 1px solid var(--blue3) !important;
    //box-shadow: none !important;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.14);
  }

  .simple-select__control--menu-is-focused {
    border: 1px solid var(--blue3) !important;
    //box-shadow: none !important;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.14);
  }

  .simple-select__indicator-separator {
    display: none;
  }

  .simple-select__indicators {
    padding: 0;
    padding-left: 4px;
    padding-right: 4px;
    background-image: url(../images/arrow-select.svg);
    background-position: center;
    background-repeat: no-repeat;

    & svg {
      display: none;
    }
  }

  .simple-select__menu {
    min-width: 100%;
    margin: 0;
    border: 1px solid var(--blue3);
    border-radius: 0;
    z-index: 100000;
    // height: auto;
  }

  .simple-select__menu-list {
    width: auto;
    max-height: 150px;
    padding: 0;

    &::-webkit-scrollbar {
      width: 4px;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background: #eff2f7;
    }

    &::-webkit-scrollbar-thumb {
      background: #c0ccd3;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &.grey-hover .simple-select__option.simple-select__option--is-focused {
    background-color: #ccc;
    color: #ffffff;
  }

  .simple-select__option {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &.simple-select__option--is-focused {
      background-color: #ccc;
      color: var(--blue3);
    }

    &.simple-select__option--is-selected {
      // background-color: #1d8fff;
      background-color: #fff;
      color: var(--blue3);

      &:hover {
        background: #ccc;
      }
    }

    & img {
      max-height: 20px;
    }
  }
}

.fin-app-status-select {
  & .simple-select .simple-select__menu{
    width: 100px;
  }
}

.border-danger {
  & .simple-select .simple-select__control {
    border: 1px solid #dc3545;
  }
}

.simple-select__single-value {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--black);
  width: 100%;
  padding-right: 8px;

  & img {
    max-height: 18px;
  }
}

.form-input {
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 6px;
  height: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #7c7c7d;
}

.text-error {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 10px;
  line-height: 1.2;
}

.input-element-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #1c1c1c;
}

.form-control.small {
  height: 28px;
  font-size: 12px;
}

.range-small {
  max-width: 100px;
}

.form-control {
  height: 36px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 20px;
  background: var(--grey4);
  border: 1px solid var(--grey4);
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  color: var(--black);

  &:hover {
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.14);
    background: var(--white);
    border-radius: 6px;
  }

  &:focus {
    border: 1px solid var(--blue3);
    outline: 0;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
  }

  &::placeholder {
    color: var(--grey3);
  }

  &:disabled {
    background: var(--grey7);
  }
}

.password-check {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 36px;
  background-image: url("../images/password.svg");
  background-repeat: no-repeat;
  background-position: center;

  &.show {
    background-image: url("../images/show_password.svg");
  }
}

.select-search {
  &__placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #c6cad3 !important;
  }

  &__single-value {
    color: #1c1c1c;
    font-size: 14px;
    font-weight: 400;
  }

  &__control {
    background-color: #fff !important;
    border-radius: 2px !important;
    border: 1px solid #ced4da !important;
    height: 36px !important;
    min-height: 36px !important;

    &--is-focused {

    }
  }

  &__value-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__menu {
    margin-top: 1px !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__clear-indicator {
    background-image: url("./../images/close_icon.svg");
    background-position: center;
    background-repeat: no-repeat;
    padding: 4px 8px !important;
    margin: 2px !important;

    &:hover {
      background-image: url("./../images/close_icon.svg");
      opacity: 0.6;
    }

    & svg {
      display: none;
    }
  }

  &-container__error {
    & .select-search__control {
      border-color: red !important;
    }
  }
}

.simple-select__indicators .simple-select__dropdown-indicator,
.simple-select__indicators .simple-select__clear-indicator {

  padding: 6px;
}

.select-search__control.select-search__control--is-disabled,
.select-search-container.select-search--is-disabled {
  background-color: #e9ecef !important;
}

.select-search-container .select-search__indicator {
  padding: 2px;
}

.select-search__dropdown-indicator {
  background-image: url("../images/arrow-select.svg");
  background-position: center;
  background-repeat: no-repeat;
  padding: 12px !important;

  & svg {
    display: none;
  }
}

.input-group--error .form-control {
  border-color: #dc3545 !important;
}

.form-control.PhoneInput input {
  border: none;
  font-size: 12px;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

.PhoneInput--disabled{
  background-color: var(--grey7);
  &:hover{
    background-color: var(--grey7);
  }
}