.btn {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
  font-size: 0.875rem;
  // line-height: 1;
  border-radius: 6px;
  padding: 6px;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  height: 36px;
  //min-width: 36px;

  &:hover {
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
  }

  &:active:hover {
    border-radius: 6px;
  }

  &:focus {
    border-radius: 6px;
  }
}

.small-btn {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}

.btn-primary {
  background-color: #00719c;
  color: #ffffff;
  line-height: 1rem;
  text-align: center;
  border: 1px solid transparent;
  height: 36px;

  & img {
    -webkit-filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
    filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
  }

  &:hover {
    background-color: #009dd9;
    border: 1px solid transparent;

    & img {
      //-webkit-filter: invert(21%) sepia(96%) saturate(2376%)
      //hue-rotate(169deg) brightness(78%) contrast(98%);
      //filter: invert(21%) sepia(96%) saturate(2376%) hue-rotate(169deg)
      //brightness(78%) contrast(98%);
    }

    &:active {
      background: #009dd9;
      border: 1px solid rgba(0, 157, 217, 0.25);
      border-radius: 6px;
    }
  }

  &:focus {
    background-color: #009dd9 !important;
    border: 1px solid rgba(0, 157, 217, 0.25);
  }

  &:disabled {
    background-color: #00719c;
    opacity: 0.5;
  }
}

.btn-outline-primary {
  background: #fdfeff;
  color: #00719c;
  padding: 5px 6px;
  border: 3px solid transparent;
  line-height: 1rem;

  &:hover {
    background-color: #00719c;
    color: white !important;
    border-color: transparent;

    &:active {
      background: #00719c;
      border: 3px solid rgba(0, 157, 217, 0.25);
    }

    & > img {
      -webkit-filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
      filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
    }
  }

  &:focus,
  &.focus {
    background-color: #00719c;
    color: white !important;
    border-color: transparent !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 113, 156, 0.25);

    & > img {
      -webkit-filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
      filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
    }
  }

  &:disabled {
    opacity: 0.5;
    background-color: #fdfeff;

    img {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }
}

.btn-outline-danger {
  background: #fdfeff;
  border: 1px solid #e9e9e9;
  color: #e2291c;
  padding: 5px 12px;
  // border: 3px solid transparent;
  &:hover {
    img {
      -webkit-filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
      filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
      fill: var(--white);
    }
  }
  &:hover {
    background: #e2291c;
    border: 1px solid #e2291c;
    color: #fdfeff;

    svg {
      path {
        stroke: #fff;
      }
    }

    &:active {
      background: #e2291c;
      color: #fdfeff;
      border: 1px solid rgba(226, 41, 28, 0.25);
      border-radius: 6px;

      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }

  &:focus {
    background-color: #e2291c !important;
    color: #fdfeff;
    border: 1px solid rgba(226, 41, 28, 0.25);

    svg {
      path {
        stroke: #fff;
      }
    }
  }
}

.btn-success {
  background: #009731;
  // border: 0.5px solid #e9e9e9;
  color: #fdfeff;
  padding: 5px 12px;
  border: 1px solid transparent;

  & img {
    -webkit-filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
    filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
  }

  &:hover {
    background: #fdfeff;
    border: 1px solid #009731;
    color: #009731;

    & img {
      filter: none;
    }

    &:active {
      background: #fdfeff;
      color: #009731;
      border: 1px solid rgba(0, 151, 49, 0.25);
      border-radius: 6px;
      // box-shadow: 2px 2px 2px
    }
  }

  &:focus {
    background-color: #fdfeff !important;
    color: #009731;
    border: 1px solid rgba(0, 151, 49, 0.25);
  }
}
.btn-outline-success {
  background-color: #fdfeff;
  color: var(--green1);
  padding: 5px;
  border: 3px solid transparent;

  &:hover {
    svg {
      -webkit-filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
      filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
      fill: var(--white);
    }
  }

  a {
    color:var(--green1);
  }

  &:hover {
    background-color: var(--green1);
    border: 3px solid var(--green1);
    color: white;

    a {
      color: #fff;
    }

    & > img {
      -webkit-filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
      filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
    }

    &:active {
      background: var(--green1);
      border: 3px solid rgba(0, 157, 217, 0.25);
    }
  }

  &:active {
    border: 3px solid rgba(0, 157, 217, 0.25);
    background-color: var(--green1) !important;
    color: white !important;

    svg {
      -webkit-filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
      filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
    }

    & > img {
      -webkit-filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
      filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
    }
  }

  &:disabled {
    color: var(--green1);
    background-color: var(--white);
    opacity: 0.5;

    img {
      filter: grayscale(1);
      opacity: 0.6;
    }

    svg {
      filter: grayscale(1);
      opacity: 0.6;
    }

    &:hover,
    &:active {
      background-color: var(--white);
      color: var(--green1);
      opacity: 0.5;

      svg {
        filter: grayscale(1);
        opacity: 0.6;
      }

      img {
        filter: grayscale(1);
        opacity: 0.6;
      }
    }
  }


}

.btn-danger {
  background: #e2291c;
  border: 0.5px solid #e2291c;
  color: #fdfeff;
  padding: 5px 12px;

  & img {
    -webkit-filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
    filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
  }

  // border: 3px solid transparent;
  &:hover {
    background: #fdfeff;
    border: 0.5px solid #e2291c;
    color: #e2291c;

    & img {
      filter: none;
    }

    &:active {
      background: #fdfeff;
      color: #e2291c;
      border: 1px solid rgba(226, 41, 28, 0.25);
      border-radius: 6px;
    }
  }

  &:focus {
    background-color: #fdfeff !important;
    color: #e2291c;
    border: 1px solid rgba(226, 41, 28, 0.25);
  }
}

.edit-expense-btn {
  &.btn-primary {
    background-color: var(--blue3);

    svg path {
      stroke: var(--white);
    }

    &:hover {
      background-color: var(--white);

      svg path {
        stroke: var(--blue3);
      }
    }
  }

  &.btn-outline-primary {
    svg path {
      stroke: var(--blue3);
    }

    &:hover {
      svg path {
        stroke: var(--white);
      }
    }
  }

}

.btn-rounded {
  width: 36px;
  height: 36px;
  min-width: 36px;
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14) !important;
  border-radius: 50% !important;

  &.small {
    width: 28px;
    height: 28px;
    padding: 4px;
  }

  &.btn-remove {
    background-image: url("../images/minus-round.svg");
  }

  &.btn-add {
    background-image: url("../images/add-round.svg");
  }
}

.btn-orange {
  background: #FF6600;
  color: #fff;
  padding: 5px 12px;
  border: 1px solid transparent;

  & img {
    -webkit-filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
    filter: invert(0%) grayscale(100%) brightness(200%) contrast(10);
  }

  &:hover {
    background: #fff;
    border: 1px solid #FF6600;
    color: #FF6600;

    & img {
      filter: none;
    }

    &:active {
      background: #fff;
      color: #FF6600;
      border: 1px solid rgba(255, 102, 0, 0.25);
      border-radius: 6px;
      // box-shadow: 2px 2px 2px
    }
  }

  &:focus {
    background-color: #fff !important;
    color: #FF6600;
    border: 1px solid rgba(255, 102, 0, 0.25);
  }
}

.btn-transparent {
  border: none;
  background-color: transparent;
  box-shadow: none;
  padding: 8px;
  outline: none;

  &:focus {
    outline: none;
    box-shadow: none;

  }

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
}


