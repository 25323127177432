.pagination-item {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
  cursor: pointer;

  &.active {
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.14);
    & button {
      color: var(--blue4);
    }
  }

  & button {
    padding: 4px;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    color: var(--black);

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

  }

  &:first-child{
    border-radius: 6px 0 0 6px;
  }

  &:last-child{
    border-radius: 0 6px 6px 0;
  }
}