.table th,
.table td {
  vertical-align: middle;
}

.table thead th:last-child,
.table thead th:first-child,
.table tbody td:last-child,
.table tbody td:first-child {
  padding: 16px 8px 16px 16px;
}
.table thead th,
.table tbody td{
  padding: 16px 8px;
}
.table-bordered thead th,
.table-bordered thead td {
  font-weight: normal;
  //font-size: 14px;
  line-height: 1.2;
  color: var(--black);
}
.column-table-filter{
  background: var(--grey4);
  border-top:1px solid var(--grey7) ;
  border-radius: 0 0 12px 12px;
  //padding: 4px 16px;
}
.filtered-table-wrapper{
  max-width: 100%;
  overflow: auto;
  //max-height: calc(100vh - 370px);
}
.full-wrap-filtered-table{
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px 12px 0 0;

}