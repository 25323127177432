.tab-wrap {
  background-color: var(--grey7);
  border-radius: 4px;
  color: var(--grey5);
  padding: 2px;
}

.tab-item {
  padding: 0 8px;
  font-size: 12px;
  cursor: pointer;

  &.active {
    background: var(--white);
    border-radius: 4px;
  }
}

.client-info-block {
  min-width: 192px;
  box-shadow: 0 1px 2px 0 #00000024;
  background: var(--grey4);
}

.account-tabs-wrapper {
  background: var(--grey7);
  color: var(--grey5);
  border-radius: 8px;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 12px;
}

.account-tabs-item {
  color: var(--grey5);
  padding: 6px 20px;

  &:hover,
  &.active {
    background: var(--white);
    border-radius: 8px;
    color: var(--black);
    text-decoration: none;
  }
}