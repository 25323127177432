::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
}
::-webkit-scrollbar-track:hover {
  background-color: #dedede;
}

::-webkit-scrollbar-thumb {
  background-color: #D1D1D1;
  min-height: 30px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #b3b3b3;
}