.loadingElement {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 10;
}

.sm-size-loader img {
    width: 48px;
    height: 48px;
}

.xs-size-loader img {
    width: 30px;
    height: 30px;
}

.load-modal {
    position: absolute;
    background: rgba(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
}

.load-modal-body {
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    width: 320px;
    padding: 32px;
    text-align: center;
}

.load-modal-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    color: #1C1C1C;
    margin-bottom: 16px;
}
.load-modal-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #1C1C1C;
}
